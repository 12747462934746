import { Container } from "./styles";
import { Route, BrowserRouter, Routes as RoutersWrapper } from "react-router-dom";
import Home from "../pages/home";
import Auth from "../pages/auth";
import Login from "../pages/login";
import { useContext } from "react";
import GlobalContext from "../context/global-context";
import Header from "../components/header";
import Stores from "../pages/Stores";
import { ThemeProvider } from "styled-components";
import theme from "../styles/theme";
import VTEXSimpleReport from "../pages/VTEXSimpleReport";
import System from "../pages/system";

const Routes = () => {
  const { token } = useContext<any>(GlobalContext)

  return (
    <Container>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          { token && <Header /> }
          <RoutersWrapper>
            <Route index element={token ? <Home /> : <Login />} />
            <Route path="/auth/google/:userToken" element={<Auth />}/>
            <Route path="/stores" element={token ? <Stores /> : <Login />}/>
            <Route path="/system" element={token ? <System /> : <Login />}/>
            <Route path="/VTEXSimpleReport" element={token ? <VTEXSimpleReport /> : <Login />}/>
          </RoutersWrapper>
        </BrowserRouter>
      </ThemeProvider>
    </Container>
  );
};

export default Routes;

import styled from 'styled-components'
import { SubTitleTypes } from './types'

const SubTitle = styled.h2<SubTitleTypes>`
  font-size: 17px;
  font-weight: 700;
  color: #882182;
  margin: ${({ margin }) => margin ?? margin};
`

export default SubTitle
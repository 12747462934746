import { useState } from "react";
import Label from "../Label";

import { Container, StyledSelect } from "./styles";
import { SelectProps } from "./types";

const Select: React.FC<SelectProps> = ({
  placeholder,
  onChange,
  label,
  theme,
  margin,
  defaultValue,
  value,
  width,
  ...rest
}) => {
  const [isFilled, setIsFilled] = useState(!!defaultValue || !!value);

  return (
    <Container margin={margin} width={width}>
      {!!label && <Label>{label}</Label>}
      <StyledSelect
        className="select"
        classNamePrefix="select"
        placeholder={placeholder ?? "Selecione"}
        defaultValue={defaultValue}
        value={value}
        onChange={(v, a) => {
          setIsFilled(true);
          onChange && onChange(v, a);
        }}
        isFilled={isFilled}
        {...rest}
      />
    </Container>
  );
};

export default Select;

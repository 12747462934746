import styled from 'styled-components'
import ReactSelect from 'react-select'
import { ContainerProps, SelectComponentProps } from './types'

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width ? width : '100%'};
  margin: ${({ margin }) => margin ?? margin};
`

export const StyledSelect = styled(ReactSelect)<SelectComponentProps>`
  .select {
    &__placeholder {
      font-size: 14px;
    }

    &__single-value {
      font-size: 14px;
    }

    &__control {
      height: 47px;
      border-radius: 5px;

    }

    &__indicator-separator {
      display: none;
    }

    &__menu {
      margin: 0;
      margin-top: 3px;

      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04),
        0px 4px 16px rgba(0, 0, 0, 0.08);
      border-radius: 5px;

      &-list {
        &::-webkit-scrollbar {
          width: 7px; /* width of the entire scrollbar */
        }

      }
    }

    &__option {
      font-size: 16px;
      line-height: 20px;
      padding: 10px 20px;
      border-top: 1px solid transparent;

      &--is-selected {
        background-color: white;
        color: hsl(0, 0%, 20%);
        font-weight: bold;
        position: relative;

        &::after {
          font-size: 18px;
          position: absolute;
          right: 20px;
          font-weight: 600;
          content: '\u2713';
          margin-left: auto;
        }
      }

      &--is-focused {
        background-color: white;
      }
    }
  }
  > div {
    /* background-color: red; */
  }
`

import { useRef } from "react";
import api from "../../Services/api";
import FormBox from "../FormBox";
import { Button } from "../shared/Button/styles";
import Input from "../shared/Input";
import * as S from "./styles";
import { FormModalProps } from "./types";

const FormModal = ({ setOpen, setStoreList }: FormModalProps) => {
  const nameRef = useRef<any>(null);
  const vtexUrlRef = useRef<any>(null);
  const logoUrlRef = useRef<any>(null);
  const appKeyRef = useRef<any>(null);
  const appTokenRef = useRef<any>(null);

  const handleClose = (e: any) => {
    if (!e.target.classList.contains("add-store-modal")) return;
    setOpen((open: boolean) => !open);
  };

  const handleSumitForm = async (e: any) => {
    e.preventDefault();
    try {
      const newData = {
        name: nameRef?.current?.value,
        vtexUrl: vtexUrlRef?.current?.value,
        logo: logoUrlRef?.current?.value,
        appKey: appKeyRef?.current?.value,
        appToken: appTokenRef?.current.value,
      };

      const { data } = await api.post("/store/create", newData);

      setStoreList((storeList: any) => [...storeList, data]);

      setOpen(false);
    } catch (error: any) {
      alert(error.message)
    }
  };

  return (
    <S.Container onClick={handleClose} className="add-store-modal">
      <FormBox title="Adding Store" onSubmit={handleSumitForm}>
        <Input
          ref={nameRef}
          margin="0 0 15px 0"
          width="100%"
          label="Name"
          placeholder="Agência e-Plus"
        />
        <Input
          margin="0 0 15px 0"
          width="100%"
          label="Vtex URL"
          placeholder="https://agenciaeplus.myvtex.com"
          ref={vtexUrlRef}
        />
        <Input
          margin="0 0 15px 0"
          width="100%"
          label="Logo URL"
          ref={logoUrlRef}
          placeholder="https://agenciaeplus.myvtex.com/arquivos/logo.png"
        />
        <Input
          margin="0 0 15px 0"
          width="100%"
          label="App Key"
          ref={appKeyRef}
          placeholder="vtexappkey-eplus-SGGBMI"
        />
        <Input
          margin="0 0 15px 0"
          width="100%"
          label="App Token"
          ref={appTokenRef}
          placeholder="DPPFNKICXKJKOGZFUSJREBCBKONBFAFNWWIKPVVOFPAABDSFTJSJGGJBMOVZXGWWFVOSXPEYXUNOBCZGVHIWIVGPNGXMAVQXTDUFPZHEFTMMTUAJOODQYAOKOZFRRRYB"
        />
        <Button>Add</Button>
      </FormBox>
    </S.Container>
  );
};

export default FormModal;

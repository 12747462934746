import { useEffect, useState } from 'react'
import api from '../../Services/api'
import * as S from './styles'

const Header = () => {
  const [ user, setUser ] = useState<any>()

  useEffect(() => {
    api.get('/user/info').then(({ data }) => {
      setUser(data)
    }).catch(error => {
      console.log(error)
    })
  }, [])
  
  return (
    <S.Container>
      <S.Logo to='/'>MKT</S.Logo>
      <S.Nav>
        <S.A to='stores'>Stores</S.A>
        <S.A to='system'>System</S.A>
      </S.Nav>
      <S.UserWrapper>
        <S.UserGreet>Hey there, </S.UserGreet>
        <S.UserName>{user?.firstName}</S.UserName>
        <S.UserImage src={user?.image} ></S.UserImage>
      </S.UserWrapper>
    </S.Container>
  )
}

export default Header
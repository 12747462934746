import { createContext, ReactNode } from "react";
import useStorage from "../../hooks/userStorage";

const GlobalContext = createContext({});

export const GlobalProvider = ({ children }: { children: ReactNode }) => {
  const [ token, setToken ] = useStorage('MKT_TOKEN')

  return (
    <GlobalContext.Provider
      value={{
        setToken,
        token
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContext;

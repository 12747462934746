import * as S from './styles'
import { StoreCardTypes } from './types'

const StoreCard = ({ name, logo, _id, vtexOrderIntegrated }: StoreCardTypes) => {
  return(
    <S.Container>
      <S.Logo src={logo ?? 'https://pbs.twimg.com/profile_images/1009142005556039690/nohA0p5H_400x400.jpg'} ></S.Logo>
      <S.StoreName>{name}</S.StoreName>
      {!vtexOrderIntegrated && <S.IntegrateText>Integrating...</S.IntegrateText>}
    </S.Container>
  )
}

export default StoreCard  
import styled from 'styled-components'
import { TitleTypes } from './types'

const Title = styled.h1<TitleTypes>`
  font-size: 25px;
  font-weight: 700;
  color: #882182;
  margin: ${({ margin }) => margin ?? margin};
`

export default Title
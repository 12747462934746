import * as S from "./styles";
import { AppCardProps } from "./types";

const AppCard = ({ to, text, icon: Icon }: AppCardProps) => {
  return <S.Container to={to}>
    {<Icon size={100}></Icon>}
    <S.Text>{text}</S.Text>
  </S.Container>;
};

export default AppCard;

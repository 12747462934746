import parse from "./parse"
import { useState } from "react"

const useStorage = (key: string) => {
  const storageItem = localStorage.getItem(key)

  const [ storage, setStorage ] = useState<string>(storageItem ? storageItem : '')

  const set = (newValue: string | object) => {
    const formatedValue = typeof newValue === 'object' ? JSON.stringify(newValue) : newValue
    setStorage(parse(newValue))
    localStorage.setItem(key, formatedValue)
  }

  return [ storage, set ] as const
}

export default useStorage
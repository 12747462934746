import GoogleOAuthButton from '../../components/GoogleOAuthButton'
import SubTitle from '../../components/shared/SubTitle/styles'
import Title from '../../components/shared/Title/styles'
import * as S from './styles'

const Login = () => {
  const handleClickLogin = () => {
    window.location.href = `${process.env.REACT_APP_GOOGLE_URL}/auth/google`
  }

  return (
    <S.Container>
      <Title>It's a pleasure to see you here</Title>
      <SubTitle margin="15px 0">I need to know who you are</SubTitle>
      <GoogleOAuthButton onClick={handleClickLogin} >Login</GoogleOAuthButton>
    </S.Container>
  )
}

export default Login
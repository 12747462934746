import styled, { css } from 'styled-components'

interface TableTitleProps {
  quantity: number
  index: number
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`

export const BoxTable = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const BoxLine = styled.div`
  display: flex;
  width: 100%;
`

export const TableTitle = styled.h3`
  font-size: 20px;
  font-weight: bold;
  width: 100%;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.grey_3};
  padding: 5px 0;
  border: 1px solid #000000;
  color: #ffffff;
`

export const TableItem = styled.span<TableTitleProps>`
  padding: 3px 5px;
  text-align: center;
  width: ${({ quantity }) => `${100 / quantity}%`};
  border-bottom: 1px solid #000000;
  border-right: 1px solid #000000;
  font-weight: 500;
  ${({ index }) => {
    if (!index) return css`
      border-left: 1px solid #000000;
      background-color: ${({ theme }) => theme.colors.grey_5};
      font-weight: 600;
    `
  }};
`

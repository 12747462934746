import styled from 'styled-components'
import { TitleProps } from './types'

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 482px;
  padding: 37px 43px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 4px 24px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  background-color: #ffffff;
`

export const Title = styled.h1<TitleProps>`
  width: 100%;
  font-style: normal;
  font-weight: ${({ isSub }) => (isSub ? 700 : 800)};
  font-size: ${({ isSub }) => (isSub ? 21 : 37)}px;
  line-height: ${({ isSub }) => (isSub ? '27px' : '130%')};
  text-align: center;
  color: ${({ theme }) => theme.colors.primary_0};
  margin-bottom: 25px;
`

import { useEffect, useRef, useState } from "react";
import Select from "../../components/Select";
import Button from "../../components/shared/Button";
import Title from "../../components/shared/Title/styles";
import Table from "../../components/Table";
import { TableItemProps } from "../../components/Table/types";
import api from "../../Services/api";
import * as S from "./styles";
import {
  exportComponentAsPNG,
} from "react-component-export-image";

const VTEXSimpleReport = () => {
  const [storeList, setStoreList] = useState([{ label: "...", value: false }]);
  const mL = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [month, setMonth] = useState(null);
  const [store, setStore] = useState("");
  const [table, setTable] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentStore, setCurrentStore] = useState("");
  const [storeReport, setStoreReport] = useState("");
  const reportBoxRef = useRef(null);

  const OPTION_LIST_MONTH = [...new Array(12)].map((_, index) => ({
    label: mL[index],
    value: index + 1,
  }));

  const getStoreList = async () => {
    const { data } = await api.get("/store/list");

    setStoreList(
      data.map((store: any) => ({ label: store.name, value: store._id }))
    );
  };

  const getTable = async () => {
    setLoading(true);
    const { data } = await api.get(`/report/vtexSimple/${month}/${store}`);

    setTable(data);
    setLoading(false);
    setStoreReport(currentStore);
  };

  const onChangeMonth = (state: any) => {
    setMonth(state.value);
  };

  const onChangeStore = (state: any) => {
    setStore(state.value);
    setCurrentStore(state.label);
  };

  useEffect(() => {
    getStoreList();
  }, []);

  return (
    <S.Container>
      <Title margin="0 0 20px 0">VTEX Report Simple Renerator</Title>
      <Select
        onChange={onChangeMonth}
        width="300px"
        margin="0 0 20px 0"
        options={OPTION_LIST_MONTH}
        placeholder="Select the month"
      />
      <Select
        onChange={onChangeStore}
        width="300px"
        margin="0 0 20px 0"
        options={storeList}
        placeholder="Select the month"
      />
      <Button onClick={getTable} width="300px">
        {loading ? "Loading..." : "Generate"}
      </Button>
      <S.ReportWrapper ref={reportBoxRef}>
        {storeReport && (
          <Title margin="50px 0 20px 0">{`Relatório ${storeReport}`}</Title>
        )}
        {storeReport && (
          <S.Time>{new Date().toLocaleDateString("pt-BR")}</S.Time>
        )}
        <S.ContainerTable>
          {table?.map((item: TableItemProps, index) => (
            <Table data={item} key={index} />
          ))}
        </S.ContainerTable>
      </S.ReportWrapper>
      {storeReport && (
        <S.WrapperButton>
          <Button
            onClick={() =>
              exportComponentAsPNG(reportBoxRef, {
                fileName: `${storeReport}-${
                  month ? mL[month - 1] : ""
                }-${new Date().toLocaleDateString("pt-BR")}`,
              })
            }
            width="300px"
          >
            Export PNG
          </Button>
        </S.WrapperButton>
      )}
    </S.Container>
  );
};

export default VTEXSimpleReport;

import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_GOOGLE_URL,
});

api.interceptors.request.use((config: any) => {
  const token = localStorage.getItem('MKT_TOKEN')?.replace(/"/g, '')

  token && (config.headers.Authorization = `Bearer ${token}`)

  return config
})

export default api;
import { TableProps } from "./types"
import * as S from './styles'

const Table = ({ data }: TableProps) => {
  return (
    <S.Container>
      <S.TableTitle>{data.title}</S.TableTitle>
      {data.lines.map((line, index) => <S.BoxLine key={index}>
        { line.map((item, index) => <S.TableItem index={index} quantity={line.length} key={index} >
          {item}
        </S.TableItem>) }
      </S.BoxLine>)}
    </S.Container>
  )
}

export default Table
import styled from 'styled-components'

export const Container = styled.li`
  width: 100%;
  min-height: 100px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 10px 15px;
  box-shadow: 0px 0px 6px #0000004D;
  margin-bottom: 20px;
`

export const Logo = styled.img`
  width: 120px;
  height: auto;
  margin-right: 25px;
`

export const StoreName = styled.h3`
  font-size: 20px;
  color: #555555;
`

export const IntegrateText = styled.span`
  color: red;
  margin: 0 auto;
`
import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Container = styled.header`
  width: 100%;
  height: 75px;
  min-height: 75px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  border-radius: 0 0 15px 15px;
  box-shadow: 0px 3px 3px #0000004D;
`

export const Logo = styled(Link)`
  font-size: 40px;
  font-family: 'Cabin', sans-serif;
  color: #882182;
  text-decoration: unset;
`

export const Nav = styled.nav`
  width: 100%;
  display: flex;
  width: 100%;
  justify-content: center;
`

export const A = styled(Link)`
  margin: 0 10px;
  text-decoration: unset;
`

export const UserWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const UserName = styled.span`
  color: #882182;
  margin-right: 15px;
  font-weight: 700;
`

export const UserGreet = styled.span`
  white-space: nowrap;
  margin-right: 5px;
`

export const UserImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
`
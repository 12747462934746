import { FiEye, FiEyeOff, FiLock } from 'react-icons/fi'
import styled, { css } from 'styled-components'

import theme from '../../../styles/theme'

import { BaseInputProps, ContainerProps } from './types'

const variantThemeColor = theme.colors.primary_0
const variantThemeColorHighlight = '#ffffff'

const outlined = css`
  border: 1px solid ${variantThemeColor};
  /* background-color: transparent; */
`

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: ${({ margin }) => margin ?? margin};
`

export const InputContainer = styled.div.attrs(
  (props: BaseInputProps) => props
)`
  color: ${variantThemeColor};
  background-color: ${variantThemeColorHighlight};
  border: 1px solid ${({ theme }) => theme.colors.grey_4};
  border-radius: 4px;
  height: 47px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  ${props => props.outlined && outlined};
  ${({ isFocused, theme }) =>
    isFocused &&
    css`
  box-shadow: 0 0 0 1px ${theme.colors.primary_0}};`}
  ${({ maxWidth }) => `max-width: ${maxWidth};`}
  ${({ theme, isErrored }) =>
    isErrored && `border-color: ${theme.colors.danger_0} !important;`};

  transition: border-color 200ms, box-shadow 200ms;
  background-color: ${({ disabled, theme }) =>
    disabled ? theme.colors.grey_6 : 'white'};
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      input {
        cursor: not-allowed;
      }
    `}

  &:hover {
    border: 1px solid ${variantThemeColor};
  }

  input {
    color: ${props => props.theme.colors.text};
    width: 100%;
    height: 100%;
    font-size: 14px;
    line-height: 17px;
    padding: 0;
    padding-left: 1em;
    outline: none;
    border: none;
    flex: 1;
    background-color: transparent;
    &::placeholder {
      color: ${props => props.theme.colors.grey_4};
      opacity: 0.6;
    }
  }
`

export const InputText = styled.input<BaseInputProps>`
  color: ${props => props.theme.colors.text};
  width: 100%;
  height: 100%;
  font-size: 14px;
  line-height: 17px;
  padding: 0;
  padding-left: 1em;
  outline: none;
  border: none;
  background-color: transparent;
  flex: 1;
  &::placeholder {
    color: ${props => props.theme.colors.grey_4};
    opacity: 0.6;
  }
`

export const IconBox = styled.div`
  color: inherit;
  width: 50px;
  height: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  position: relative;
`

export const Lock = styled(FiLock)`
  color: inherit;
  width: 17px;
  height: 17px;
  min-width: 17px;
  min-height: 17px;
  margin-left: 10px;
  transform: translateY(-1px);
`
export const Visible = styled(FiEye)`
  width: 27px;
  height: 24px;
  min-width: 27px;
  min-height: 24px;
  transform: translateY(-1px);
  position: absolute;
  color: ${props => props.theme.colors.grey_4};
`
export const Hidden = styled(FiEyeOff)`
  position: absolute;
  width: 27px;
  height: 24px;
  min-width: 27px;
  min-height: 24px;
  transform: translateY(-1px);
  color: ${props => props.theme.colors.grey_4};
`

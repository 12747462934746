import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Container = styled(Link)`
  display: flex;
  width: 200px;
  height: 200px;
  border: 1px solid ${({ theme }) => theme.colors.primary_1};
  border-radius: 5px;
  transition: 300ms;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primary_1};
  box-shadow: 5px 5px 5px #0000004d;

  &:hover {
    box-shadow: 1px 1px 1px #0000004d;
  }
`

export const Text = styled.h4`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.primary_1};
  text-align: center;
  margin-top: 15px;
  text-decoration: none;
`
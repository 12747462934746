import AppCard from "../../components/AppCard";
import * as S from "./styles";
import { BsGraphUp } from 'react-icons/bs'

const Home = () => {
  return <S.Container>
    <AppCard to='/VTEXSimpleReport' text='VTEX simple report' icon={BsGraphUp}/>
  </S.Container>;
};

export default Home;

import styled from 'styled-components'

export const Container = styled.section`
  width: 100%;
  height: 100%;
  backdrop-filter: blur(7px);
  background-color: #0000004D;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
`
import { GlobalProvider } from "./context/global-context";
import Routes from "./routes";
import './reset.css'

function App() {
  return (
    <GlobalProvider>
      <Routes />
    </GlobalProvider>
  )
}

export default App;

const theme = {
  colors: {
    background: '#ffffff',
    text: '#323730',

    // colors figma
    primary__3: '#F2E3F0', // custom
    primary__2: '#F2C8EF',
    primary__1: '#D472CF',
    primary_0: '#882182',
    primary_1: '#682D76',

    grey_1: '#212520',
    grey_2: '#323730',
    grey_3: '#5A6256',
    grey_4: '#ABB3A8',
    grey_5: '#E3E6E2',
    grey_6: '#F9F9F9',

    success__2: '#E2F3EF',
    success_0: '#34806E',
    success_1: '#1F4D42',

    danger__2: '#FAE4E7',
    danger_0: '#DA2A41',
    danger_1: '#A32C2E',

    warning__2: '#FFF6DB',
    warning_0: '#FFC50D',
    warning_1: '#F1B700',

    info__2: '#E3F6FB',
    info_0: '#1DB9E0',
    info_1: '#1588A4'
  },
  sizes: {
    appCard: {
      small: {
        width: '238px',
        height: '158px'
      },
      default: {
        width: '321px',
        height: '213px'
      }
    }
  }
}

export default theme

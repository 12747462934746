import styled, { css } from 'styled-components'
import theme from '../../../styles/theme'
import { ButtonProps } from './types'

const { colors } = theme

interface Theme {
  text: string
  hoverText?: string
  background?: string
  hoverBackground?: string
  border?: string
  hoverBorder?: string
}

const buttonSchemas: { [name: string]: Theme } = {
  primary: {
    text: 'white',
    background: colors.primary_0,
    hoverBackground: colors.primary_1
  },
  secondary: {
    text: colors.primary_1,
    background: colors.primary__2,
    hoverBackground: colors.primary__1
  },
  success: {
    text: 'white',
    background: colors.success_0,
    hoverBackground: colors.success_1
  },
  danger: {
    text: 'white',
    background: colors.danger_0,
    hoverBackground: colors.danger_1
  },
  warning: {
    text: colors.grey_1,
    background: colors.warning_0,
    hoverBackground: colors.warning_1
  },
  info: {
    text: 'white',
    background: colors.info_0,
    hoverBackground: colors.info_1
  },
  primaryAlt: {
    text: colors.grey_2,
    background: 'white',
    hoverBackground: colors.primary__2
  },
  primaryOut: {
    text: colors.primary_0,
    hoverText: colors.primary_1,
    border: colors.primary_0,
    hoverBorder: colors.primary_1,
    hoverBackground: colors.primary__2
  },
  primaryAltOut: {
    text: 'white',
    border: 'white',
    background: colors.primary_1,
    hoverBackground: '#78277C'
  },
  secondaryOut: {
    text: colors.grey_2,
    hoverText: colors.grey_1,
    border: colors.grey_2,
    hoverBorder: colors.grey_1
  },
  successOut: {
    text: colors.success_0,
    hoverText: colors.success_1,
    border: colors.success_0,
    hoverBorder: colors.success_1
  },
  dangerOut: {
    text: colors.danger_0,
    hoverText: colors.danger_1,
    border: colors.danger_0,
    hoverBorder: colors.danger_1
  },
  warningOut: {
    text: colors.grey_1,
    border: colors.warning_0,
    hoverBorder: colors.warning_1
  },
  infoOut: {
    text: colors.info_0,
    hoverText: colors.info_1,
    border: colors.info_0,
    hoverBorder: colors.info_1
  }
}

const buttonSizes = {
  big: '47px',
  medium: '39px',
  small: '37px'
}

const getSchema = ({
  schema = 'primary',
  outlined,
  alternative,
  disabled
}: ButtonProps) => {
  const parsedSchema = `${schema}${alternative ? 'Alt' : ''}${
    outlined ? 'Out' : ''
  }`
  const schemaObj = buttonSchemas[parsedSchema]

  if (disabled) {
    return css`
      cursor: not-allowed;
      color: ${!outlined
        ? colors.grey_3
        : alternative
        ? colors.grey_5
        : colors.grey_4};
      background-color: ${!outlined ? colors.grey_5 : 'transparent'};
      border: 1px solid ${outlined ? colors.grey_5 : colors.grey_5};
    `
  }

  return css`
    color: ${schemaObj.text};
    border: 1px solid ${schemaObj.border ?? 'transparent'};
    background-color: ${schemaObj.background ?? 'transparent'};

    &:hover {
      ${schemaObj.hoverBackground &&
      css`
        background-color: ${schemaObj.hoverBackground};
      `}
      ${schemaObj.hoverBorder &&
      css`
        border-color: ${schemaObj.hoverBorder};
      `}
      ${schemaObj.hoverText &&
      css`
        color: ${schemaObj.hoverText};
      `}
    }
  `
}

export const Button = styled.button<ButtonProps>`
  width: ${({ width }) => width ?? '100%'};
  height: ${({ size }) => buttonSizes[size ?? 'big']};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ size }) => (size === 'small' ? '14px' : '16px')};
  line-height: 19px;
  border-radius: 5px;
  cursor: pointer;
  margin: ${({ margin }) => margin ?? 0};
  padding: 11px ${({ size }) => (size === 'small' ? '16px' : '25px')};
  transition: background-color 200ms, border-color 200ms;

  ${getSchema}
`

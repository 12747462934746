import { useContext, useEffect } from 'react'
import { useParams, Route, Navigate, Routes } from 'react-router-dom'
import GlobalContext from '../../context/global-context'

const Auth = () => {
  const { userToken } = useParams()
  const { token, setToken } = useContext<any>(GlobalContext)

  useEffect(() => {
    setToken(userToken ? userToken : '')
  }, [setToken, userToken])

  return (!token ? <h1>Autenticado</h1> : <Routes><Route path="*" element={<Navigate to='/' replace />} /></Routes>)
}

export default Auth
import React, { forwardRef, useCallback, useState } from 'react'
import Label from '../Label'
import { BaseInputProps } from './types'
import * as Styled from './styles'

export const maskDict = {
  phone: {
    delimiters: ['(', ') ', '-'],
    blocks: [0, 2, 4, 4],
    numericOnly: true,
    delimiterLazyShow: true
  },
  cellphone: {
    delimiters: ['(', ') ', '-'],
    blocks: [0, 2, 5, 4],
    numericOnly: true,
    delimiterLazyShow: true
  },
  cnpj: {
    delimiters: ['.', '.', '/', '-'],
    blocks: [2, 3, 3, 4, 2],
    numericOnly: true,
    delimiterLazyShow: true
  },
  url: {
    prefix: 'https://'
  }
}

const Input = (
  {
    label,
    leftIcon: LeftIcon,
    rightIcon: RightIcon,
    required,
    placeholder,
    value,
    onChange,
    onClickIcon,
    type,
    name,
    id,
    defaultValue,
    isErrored,
    mask,
    labelRequired,
    disabled,
    margin,
    ...rest
  }: BaseInputProps,
  ref: any
) => {
  const [showPassword, setShowPassword] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const [isFilled, setIsFilled] = useState(!!value || !!defaultValue)

  const handleTogglePassword = useCallback(() => {
    setShowPassword(prev => !prev)
  }, [])

  const handleInputFocus = useCallback(() => {
    setIsFocused(true)
  }, [])

  const handleInputBlur = useCallback(() => {
    setIsFocused(false)
    const inputRef = ref as React.MutableRefObject<HTMLInputElement>
    setIsFilled(!!inputRef?.current?.value)
  }, [ref])

  return (
    <Styled.Container margin={margin}>
      {!!label && <Label required={labelRequired}>{label}</Label>}
      <Styled.InputContainer
        isErrored={isErrored}
        outlined={isFilled || isFocused}
        isFocused={isFocused}
        disabled={disabled}
        {...rest}
      >
        {LeftIcon && (
          <Styled.IconBox onClick={onClickIcon}>
            <LeftIcon />
          </Styled.IconBox>
        )}
        {(
          <input
            name={name}
            placeholder={placeholder}
            type={!showPassword ? type : 'text'}
            value={value}
            onChange={onChange}
            ref={ref}
            id={id}
            defaultValue={defaultValue}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            required={required}
            disabled={disabled}
          />
        )}
        {RightIcon ? (
          <Styled.IconBox onClick={onClickIcon}>
            <RightIcon />
          </Styled.IconBox>
        ) : (
          type === 'password' && (
            <Styled.IconBox onClick={handleTogglePassword}>
              {showPassword ? <Styled.Visible /> : <Styled.Hidden />}
            </Styled.IconBox>
          )
        )}
      </Styled.InputContainer>
    </Styled.Container>
  )
}

export default forwardRef(Input)

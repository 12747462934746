import React from 'react'

import { FormContainer, Title } from './styles'
import { FormBoxProps } from './types'

const FormBox: React.FC<
  FormBoxProps & React.FormHTMLAttributes<HTMLFormElement>
> = ({ title, isSub = false, children, ...rest }) => {
  return (
    <FormContainer {...rest}>
      <Title isSub={isSub}>{title}</Title>
      {children}
    </FormContainer>
  )
}

export default FormBox

import { useEffect, useState } from "react";
import FormModal from "../../components/FormModal";
import Button from "../../components/shared/Button";
import Title from "../../components/shared/Title/styles";
import StoreCard from "../../components/StoreCard";
import api from "../../Services/api";
import * as S from "./styles";

const Stores = () => {
  const [storeList, setStoreList] = useState<Array<any>>([]);
  const [ modalOpen, setModalOpen ] = useState<boolean>(false)

  const handleClickAdd = () => {
    setModalOpen(true)
  }

  const getStoreList = async () => {
    const { data } = await api.get("/store/list");

    console.log(data);

    setStoreList(data);
  };

  useEffect(() => {
    getStoreList();
  }, []);

  return (
    <S.Container>
      <Title margin="0 0 30px 0">Store list</Title>
      <S.StoreList>
        {storeList.map((item) => (
          <StoreCard
            name={item.name}
            logo={item.logo}
            _id={item._id}
            key={item._id}
            vtexOrderIntegrated={item.vtexOrderIntegrated}
          />
        ))}
      </S.StoreList>
      <Button onClick={handleClickAdd} width="150px">Add store</Button>
      { modalOpen && <FormModal setStoreList={setStoreList} setOpen={setModalOpen}></FormModal> }
    </S.Container>
  );
};

export default Stores;

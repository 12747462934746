import styled from "styled-components";

export const Container = styled.main`
  width: 100%;
  height: 100%;
  padding: 20px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const ContainerTable = styled.div`
  width: 100%;
  margin-top: 30px;
  margin: 0 2px;
`

export const Time = styled.span`
  font-size: 13px;
  color: #5A6256;
  margin-bottom: 20px;
`

export const ReportWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const WrapperButton = styled.div`
  display: flex;
  padding-bottom: 40px;
`
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const StoreList = styled.ul`
  width: 100%;
  max-width: 900px;
  height: 80%;
  max-height: 74vh;
  border: 1px solid #882182;
  border-radius: 5px;
  padding: 20px;
  padding-bottom: 0;
  overflow: auto;
  margin-bottom: 20px
`
import { useState } from "react";
import Button from "../../components/shared/Button";
import api from "../../Services/api";
import * as S from './styles'

const System = () => {
  const [ reindex, setReindex ] = useState<boolean>(false)
  const handleReindex = async () => {
    await api.post(`/system/reindex`);
    setReindex(true)
  }
  return (
    <S.Container>
      <Button onClick={handleReindex}>Reindex</Button>
      { reindex && <p>Reindex...</p> }
    </S.Container>
  );
};

export default System;
